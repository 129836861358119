<template>
  <van-popup v-model="show" round position="bottom">
    <van-datetime-picker v-model="currentDate" type="datehour" :min-date="minDate" :formatter="formatter"
      :filter="filter" @confirm="confirmFun" @change="changeFun" @cancel="cancelFun" />
  </van-popup>
</template>
<script>
  import moment from 'moment'
  import { getBeginDateAndHoursArray } from '@/assets/js/utils.js'
  export default {
    name: 'chooseTime',
    props: {
      showDate: {
        type: Boolean,
        default: false
      },
      defaultData: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        show: false,
        timeArray: [],//可选的时间
        timeIndex: 0,//时间
        minDate: new Date(),
        maxDate: new Date(2025, 10, 1),
        currentDate:this.defaultData ? new Date(this.defaultData) : new Date(),
      };
    },
    watch: {
      showDate(val, oldVal) {//普通的watch监听
        console.log(oldVal);
        console.log(val);
        if(val != oldVal){
          this.show = val
        }
      },
      defaultData(val, oldVal) {//普通的watch监听 
        this.currentDate = new Date(val)
      },
    },
    mounted() {
      this.show = this.showDate
      this.getTimeRang()
    },
    methods: {
      //取消
      cancelFun() {
        this.$emit('datePopupFun')
      },
      //保存
      confirmFun(val) {
        this.$emit('getTime', moment(this.currentDate).format('YYYY-MM-DD HH'))
      },
      changeFun(val) {
        this.getTimeRang()
      },
      getTimeRang() {
        let data = getBeginDateAndHoursArray(moment(this.currentDate).format('YYYY-MM-DD'))
        this.timeArray = data.timeRang
      },
      //时间过滤
      filter(type, options) {
        if (type === 'hour') {
          return this.timeArray
        }
        return options;
      },
      //日期
      formatter(type, val) {
        if (type === 'year') {
          return `${val}年`;
        } else if (type === 'month') {
          return `${val}月`;
        } else if (type === 'day') {
          return `${val}日`;
        } else {
          return `${val}时`;
        }
        return val;
      },

    },
  };
</script>