<template>
  <div class="choose-warehouse">
    <van-popup v-model="show" round position="bottom">
      <van-cascader
        v-model="chooseCity"
        title="请选择"
        placeholder="请选择"
        :options="options"
        :field-names="fieldNames"
        @close="closeFun"
        @finish="finishFun"
      />
    </van-popup>
  </div>
</template>

<script>
const API = require("/src/assets/js/apilist.js");
  let userJson = JSON.parse(localStorage.getItem('user'));
export default {
  name: "",
  props: {
    showCity: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
    cityData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    depotOption: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      show: false,
      chooseCity: "", //340102
      fieldNames: { text: "label", value: "value", children: "children" },
      options: [],
      addOptions: [],
      selectedOptions: [],
    };
  },
  watch: {
    showCity(val, oldVal) {
      //普通的watch监听
      console.log(val);
      this.show = val;
    },
    cityData(val, oldVal) {
      this.options = val;
    },
  },
  mounted() {
    if (this.$route.query.id) {
    // mounted拿不到详情传过来的选中仓库数组，所以用unwatch监听一下
      const unwatch = this.$watch(
        "depotOption",
        function (newValue, oldValue) {
          if (newValue && newValue.length > 0) {
            this.selectedOptions = newValue;
            this.chooseCity = newValue[3];
            this.getDetailCityList(this.selectedOptions);
            if (unwatch) {
              unwatch();
            }
          }
        },
        { deep: true }
      );
    } else {
      this.getCityFun(); //仓库地址
    }
  },
  methods: {
    //仓库遍历
    warehouseFun() {
      let self = this;
      let selectedOptions = this.selectedOptions;
      for (let i = 0; i < self.options.length; i++) {
        let father = self.options[i];
        if (father.value == selectedOptions[0].value) {
          let child = father.children;
          for (let j = 0; j < child.length; j++) {
            let grandson = child[j].children;
            if (child[j].value == selectedOptions[1].value) {
              for (let z = 0; z < grandson.length; z++) {
                if (grandson[z].value == selectedOptions[2].value) {
                  self.options[i].children[j].children[z]["children"] =
                    self.addOptions;
                }
              }
            }
          }
        }
        self.$set(this.options);
      }
    },
    //仓库选择确定
    finishFun({ value, selectedOptions, tabIndex }) {
      let self = this;
      this.selectedOptions = selectedOptions;
      let last = selectedOptions[selectedOptions.length - 1];
      let codeArr = selectedOptions.map((i) => {
        return i.value;
      });
      if (!last.children) {
        //如果不存在children
        if (last.value.length < 8) {
          this.getDepot(codeArr, self.warehouseFun);
        } else {
          self.$emit("chooseWarehouse", last);
        }
      }
    },
    //展示仓库弹框
    closeFun(isFlay) {
      this.$emit("wPopupFun", false);
    },
    //城市地址
    getCityFun() {
        let path = this.type == "in" ? API.entryCity : API.outCity;
        let params = this.type == "in" ? {}:{ corpId:userJson.corpId };
        this.$axios("post",path,params).then((res) => {
          if (res && res.code == "200") {
            this.options = res.data;
            console.log("获取城市地址", this.options);
          } else {
            this.$toast2.show("城市地址获取出错了！", "error");
          }
        });
    },
    // 获取出、入库详情仓库初始数据
    getDetailCityList(value) {
      let cityPath = this.type == "in" ? API.entryCity : API.outCity;
      let cityParams = this.type == "in" ? {}:{ corpId:userJson.corpId };
      let depotPath = this.type == "in" ? API.entryDepot : API.outDepot;
      // 调用仓库接口的参数
      let params = {
        current: 1,
        size: 30,
        name: "",
        contractName: "",
        province: value[0],
        city: value[1],
        district: value[2],
      };
      let data = [];
      this.$axios("post",cityPath, cityParams)
        .then((res) => {
          data = res.data;
          if (res.code == "200") {
            this.$axios("post", depotPath, params)
              .then((res) => {
                console.log(res, "resddd");
                let result = res.data.records;
                data.forEach((item) => {
                  if (item.value === value[0]) {
                    item.children.forEach((item2) => {
                      if (item2.value === value[1]) {
                        item2.children.forEach((item3) => {
                          if (item3.value === value[2]) {
                            item3.children = [];
                            result.map((item4) => {
                              item3.children.push(
                                Object.assign({
                                  value: item4.id,
                                  label: item4.name,
                                })
                              );
                            });
                          }
                        });
                      }
                    });
                  }
                });
                this.options = data;
                this.$set(this.options);
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {});
    },
    //获取仓库地址
    getDepot(codeArr, callback) {
      console.log(233);
      let self = this;
      let parms = {
        current: 1,
        size: 30,
        name: "",
        contractName: "",
        province: codeArr[0],
        city: codeArr[1],
        district: codeArr[2],
      };
      let path = this.type == "in" ? API.entryDepot : API.outDepot;
      this.$axios("post", path, parms).then((res) => {
        if (res && res.code == "200") {
          let records = res.data.records;
          console.log("打印仓库地址数据", records);
          if (records.length) {
            self.addOptions = records.map((i) => ({
              label: i.name,
              value: i.id,
              // return i
            }));
            //console.log("打印map重构之后的数据", self.addOptions)
          }
        }
        // 执行回调
        callback();
      });
    },
  },
};
</script>
<style scoped>
.choose-warehouse >>> .van-cascader__option--selected {
  color: #007ec5 !important;
}
.choose-warehouse >>> .van-tabs__line {
  background-color: #007ec5 !important;
}
</style>