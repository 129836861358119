<template>
  <div class="warehouse">
    <headNav></headNav>
    <div class="flex_m tabs">
      <div class="flex1" @click="jumpFun">入库单</div>
      <div class="flex1 active">出库单</div>
    </div>
    <van-form @submit="saveFun" class="w-box">
      <div class="mew-box">
        <div class="flex_m b-line h100">
          <span class="m-label"><i class="star-red fz26 mr5">*</i>客户名称</span>
          <van-field class="flex1" v-model="parmsJson.companyName" :rules="[{ required: true}]" placeholder="客户名称" />
        </div>
        <div class="flex_m h100">
          <span class="m-label"><i class="star-red fz26 mr5">*</i>仓库信息</span>
          <van-field class="flex1" @click="wPopupFun(true)" v-model="parmsJson.depotName" :rules="[{ required: true}]"
            right-icon="arrow-down" placeholder="仓库信息" readonly />
        </div>
      </div>
      <div class="mew-box mt30">
        <div class="flex_m b-line h100">
          <span class="m-label">预计出库时间</span>
          <van-field @click="datePopupFun(true)" class="flex1" v-model="parmsJson.takeTime" right-icon="arrow-down"
            placeholder="预计出库时间" readonly />
        </div>
        <div class="flex_m b-line h100">
          <span class="m-label"><i class="star-red fz26 mr5">*</i>出库方式</span>
          <div class="flex1 flex_end">
            <span @click='chooseTypeFun(item,"checkoutType")'
              :class="['tbutton_bg mr20',{'tbutton_bg_cur':parmsJson.checkoutType == item.value}]"
              v-for="item in outTypeArr">{{item.text}}</span>
          </div>
        </div>
        <div class="flex_m b-line h100">
          <span class="m-label">增值服务</span>
          <van-field @click="showSddServicesFun(true)" class="flex1" v-model="addedServicesName" right-icon="arrow-down"
            placeholder="增值服务" readonly />
        </div>
        <div class="flex_m h100">
          <span class="m-label"><i class="star-red fz26 mr5">*</i>付款方式</span>
          <div class="flex1 flex_end">
            <span @click='chooseTypeFun(item,"paymentType")'
              :class="['tbutton_bg mr20',{'tbutton_bg_cur':parmsJson.paymentType == item.value}]"
              v-for="item in outPayArr">{{item.text}}</span>
          </div>
        </div>
      </div>
      <div class="flex_m mt20">
        <span class="flex1 f888 text-line" @click="jumpStandards">收货、收费标准</span>
        <van-button @click="addGoodsFun" class="plain bg-fff h70 pl30 pr30 icon_m b-radius-p50">选择货品明细</van-button>
      </div>
      <template v-if="parmsJson.commodityJson && parmsJson.commodityJson.length">
        <div class="mt20">
          <div class="t-tr bg-w">
            <div class="th" v-for="(thName,thIdx) in tHeadData">{{thName}}</div> <!-- :class="['th',{'w'+thIdx}]"  -->
            <div class="th lastW tc"></div>
          </div>
          <div class="t-tr" v-for="(item,index) in parmsJson.commodityJson">
            <div class="td w1">{{item.code}}</div>
            <div class="td w2">{{item.name}}</div>
            <div class="td">{{item.productionTime}}</div>
            <div class="td">
              <van-field type='digit' class="inpt" @input="inputEnterNumberFun(item,index)" v-model="item.outNumber"
                :rules="[{ required: true}]" placeholder="出库数量" />
            </div>
            <div class="td lastW tc">
              <van-icon class="f333 fz34" name="delete-o" @click="deleteFun(index)" />
            </div>
          </div>
        </div>
        <div class="h100">
          <div class="f888 fz26 tr pt20">合计：<span
              class="icon_m">{{parmsJson.totalPakages}}箱、{{parmsJson.totalWeight}}kg、{{parmsJson.totalVolume}}方</span>
          </div>
        </div>
      </template>
      <div class="mew-box mt20">
        <div class="flex_m b-line h100">
          <span class="m-label"><i class="star-red fz26 mr5">*</i>配送方式</span>
          <div class="flex1 flex_end">
            <span @click='chooseTypeFun(item,"deliveryType")'
              :class="['tbutton_bg mr20',{'tbutton_bg_cur':parmsJson.deliveryType == item.value}]"
              v-for="item in outTalkArr">{{item.text}}</span>
          </div>
        </div>
        <div class="flex_m b-line h100">
          <span class="m-label">提货司机</span>
          <van-field class="flex1" v-model="parmsJson.driverName" placeholder="请输入" />
        </div>
        <div class="flex_m b-line h100">
          <span class="m-label">司机联系方式</span>
          <van-field class="flex1" v-model="parmsJson.driverPhone" placeholder="请输入" />
        </div>
        <div class="flex_m b-line h100">
          <span class="m-label">车牌号</span>
          <van-field class="flex1" v-model="parmsJson.licenseNumber" placeholder="请输入" />
        </div>
      </div>
      <div class="mew-box mt20">
        <div class="flex_m">
          <span class="m-label">备注</span>
          <van-field v-model="parmsJson.remark" rows="1" autosize type="textarea" placeholder="请输入" show-word-limit />
        </div>
      </div>
      <div class="flex_m h100 m-fixed bg-fff">
        <div class="flex1 f666 ml20 flex_m">
          <van-checkbox v-model="parmsJson.isChecked" @change="checkedFun" class="mr10" icon-size="24px"></van-checkbox>
          <span class="flex1 ml10">我已经阅读并同意<span class="icon_s" @click="agreementFun">《仓储条款》</span></span>
        </div>
        <van-button native-type="submit" class="btn-square">{{actType == 'edit' ? '修改出库订单':'生成出库订单' }}</van-button>
      </div>
    </van-form>

    <!--日期选择-->
    <ChooseTime :showDate="showDate" :defaultData="parmsJson.warehousingTime" @getTime="getTimeFun"
      @datePopupFun="datePopupFun"></ChooseTime>

    <!--仓库选择-->
    <ChooseWarehouse :type="'out'" :showCity="showCity" :depotOption='depotOption' :cityData="cityData" @chooseWarehouse="chooseWarehouse"
      @wPopupFun="wPopupFun">
    </ChooseWarehouse>

    <!--增值服务-->
    <van-popup v-model:show="showSddServices" closeable position="bottom" :style="{ height: '70%' }">
      <div class="auto-x">
        <van-checkbox-group v-model="parmsJson.valueAddedServices" checked-color="#007EC5" class="pt30 pl20 pr20">
          <van-row wrap>
            <van-col span="12" v-for="item in plainOptions" class="pt30" :key="item.id">
              <van-checkbox :name="item.feeType" class="wp50">{{item.feeTypeName}}</van-checkbox>
            </van-col>
          </van-row>
        </van-checkbox-group>
        <div class="tc mt30 absolute-b">
          <van-button @click="changeNameFun" round type="info" size='small' class="pl30 pr30">保存</van-button>
        </div>
      </div>
    </van-popup>
  </div>

</template>
<script>
  import headNav from '@/components/mobile/headNav.vue'
  import ChooseTime from './components/m_choose_time.vue'
  import ChooseWarehouse from './components/m_choose_warehouse.vue'
  import { regExp } from "../../../assets/js/global";
  import moment from 'moment'
  import { getBeginDateAndHoursArray } from '@/assets/js/utils.js'
  import { outInitialParms, outTypeArr, outPayArr, outTalkArr, totalFun } from '@/assets/js/warehouse.js'
  const API = require('/src/assets/js/apilist.js');
  //获取用户信息
  let userJson = JSON.parse(localStorage.getItem('user'));
  export default {
    name: 'out',
    components: {
      ChooseTime,
      ChooseWarehouse,
      headNav
    },
    data() {
      return {
        parmsJson: {...outInitialParms,depotId:''}, //保存的集合
        showDate: false, //日期
        outTypeArr,//出口方式
        outPayArr,//支付方式
        outTalkArr,//配送方式
        tHeadData: ['商品编号', '商品名称', '生产日期', '出库数量'], //已选商品的表头
        showCity: false,//仓库弹框
        warehousingTime: '',//格式化化时间到时
        rules: {
          integer: regExp('integer')
        },
        actType: '',//空:(默认)新建 add:再下一单 edit:编辑
        cityData: [],//出库仓库城市
        plainOptions: [],//增值服务集合
        showSddServices: false,//增值服务的弹框
        addedServicesName: '',//选中增值服务的中文展示
        depotOption:[]
      };
    },
    mounted() {
      let parmsJson = localStorage.getItem('parmsJson')
      let id = this.$route.query.id || ''
      this.actType = this.$route.query.actType
      if (id) {
        this.$set(this.parmsJson, 'id', id)
        this.getDetail(id)
      } else if (parmsJson) { //已经选择过的回选
        this.parmsJson = JSON.parse(parmsJson)
        this.warehousingTime = this.parmsJson.takeTime ? this.parmsJson.takeTime.split(':')[0] + '时' : ''
        if (this.parmsJson.commodityJson) {
          this.inputEnterNumberFun()
        }

      } else {
        this.getCityFun()
        this.userMsgFun(userJson)
      }

      let depotId = this.parmsJson.depotId
      console.log('depotIddepotIddepotId',depotId);
      if (depotId) {//有仓库id就获取增值服务
        this.getSddServices(depotId)
      }
      // this.getCityFun()
      // this.userMsgFun(userJson)


    },
    methods: {
      //获取仓库地址,如果没有仓库没有出库
      getCityFun() {
        let parms = { corpId: userJson.corpId || '' }
        this.$axios('post', API.outCity, parms).then(res => {
          if (res && res.code == '200') {
            if (res.data && res.data.length) {
              this.cityData = res.data
            } else {
              this.$toast2.show('没有仓库信息！', 'error')
            }
          } else {
            this.$toast2.show('城市地址获取出错了！', 'error')
          }
        })
      },
      //编辑获取详情
      getDetail(id) {
        let self = this
        this.$axios('get', API.outGetById + '?id=' + id).then(res => {
          if (res && res.code == '200' && res.data) {
            let parmsJson = self.parmsJson
            let data = res.data
            Object.keys(parmsJson).map(function (key) {
              data[key] && (parmsJson[key] = data[key])
            })
            parmsJson.commodityJson = data.depotStock
            self.depotOption = (data.province && data.city && data.district && data.depotId) ? [data.province, data.city, data.district, data.depotId]:[]
            parmsJson.valueAddedServices = data.valueAddedServices ? data.valueAddedServices.split(',') : ''
            self.parmsJson = parmsJson
            if (data.depotId) {//有仓库id就获取增值服务
              this.getSddServices(data.depotId)
            }
          }
        })
      },
      //生成出库单
      saveFun() {
        let parmsJson = this.parmsJson
        let commodityJson = parmsJson.commodityJson
        if (!parmsJson.checkoutType) {
          this.$toast2.show('请选择出库方式', 'warn')
          return false
        }
        if (!parmsJson.paymentType) {
          this.$toast2.show('请选择付款方式', 'warn')
          return false
        }
        if (commodityJson.length == 0) {
          this.$toast2.show('请至少选择一个货品', 'warn')
          return false
        }
        if (!parmsJson.deliveryType) {
          this.$toast2.show('请选择配送方式', 'warn')
          return false
        }
        if (!this.parmsJson.isChecked) {
          this.$toast2.show('请阅读并同意《仓储条款》', 'warn')
          return false
        }
        let requiredTotalArr = [
          { valName: 'totalPakages', messages: '入库总数量没有计算' },
          { valName: 'totalVolume', messages: '入库总体积没有计算' },
          { valName: 'totalWeight', messages: '入库总重量没有计算' },
        ]
        commodityJson.length && requiredTotalArr.map(msg => {
          if (!parmsJson[msg.valName] && faly) {
            this.$toast2.show(msg.messages, 'error')
            return false
          }
        })
        localStorage.setItem('parmsJson', JSON.stringify(this.parmsJson))
        this.$router.push({ path: '/m_warehouse_detail', query: { type: 'out', id: '', actType: this.actType } });

      },
      //同意条款
      checkedFun(val) {
        // this.isChecked = val
        this.$set(this.parmsJson, 'isChecked', val)
      },
      agreementFun() {
        localStorage.setItem('parmsJson', JSON.stringify(this.parmsJson))
        this.$router.push({ path: '/m_agreement' });
      },
      //补充客户信息
      userMsgFun(userJson) {
        this.parmsJson = {
          ...this.parmsJson,
          companyName: userJson.companyName || '', //公司名称
          corpId: userJson.corpId || '', //公司Id
          userId: userJson.id || '', //用户ID
          mobile: userJson.mobile || '', //手机号
        }
      },
      //保存选中的增值服务
      changeNameFun() {
        let choose = this.parmsJson.valueAddedServices
        let nameArr = []
        this.plainOptions.map(f => {
          choose.length && choose.map(c => {
            if (f.feeType == c) {
              nameArr.push(f.feeTypeName)
            }
          })
        })
        this.addedServicesName = (nameArr || []).join(',')
        this.showSddServices = false
      },
      //弹出增值服务
      showSddServicesFun(isFlay = false) {
        if (this.plainOptions && this.plainOptions.length) {
          this.showSddServices = isFlay
        } else {
          this.$toast2.show('没有选择仓库或者是没有增值服务哦！', 'warn')
        }
      },
      //获取增值服务
      getSddServices(depotId) {
        let self = this
        let parms = { depotId, state: '1' }
        this.$axios('post', API.findAddedValuesInit, parms).then(res => {
          if (res && res.code == '200') {
            let data = res.data
            self.plainOptions = data
            if (data.length) {
              self.changeNameFun()
            }
          } else {
            this.$toast2.show('获取增值服务出错了！', 'error')
          }
        })
      },
      //仓库选择
      chooseWarehouse(data) {
        if (data) {
          this.parmsJson = {
            ...this.parmsJson,
            depotId: data.value, //仓库ID
            depotName: data.label,//仓库名称
          }
          if (data.value) {//有仓库id就获取增值服务
            this.getSddServices(data.value)
          }
          
        }
        this.wPopupFun()
      },
      //仓库选择弹框
      wPopupFun(isFlay = false) {
        console.log(isFlay);
        this.showCity = isFlay
      },
      //入库时间
      getTimeFun(data) {
        this.$set(this.parmsJson, 'takeTime', data + ':00:00')
        this.$set(this, 'warehousingTime', data + '时')
        this.datePopupFun()
      },
      //时间弹框
      datePopupFun(isFlay = false) {
        this.showDate = isFlay
      },
      //点击收货、收费标准
      jumpStandards(e) {
        e.preventDefault()
        localStorage.setItem('parmsJson', JSON.stringify(this.parmsJson))
        this.$router.push({ path: '/m_standards' });
      },
      //点击选择货品明细
      addGoodsFun(e) {
        e.preventDefault()
        let self = this

        if (!this.parmsJson.depotId) {
          self.$dialog.alert({ confirmButtonText: '好的', message: '请选择仓库才能选择货品明细' });
          return
        }
        localStorage.setItem('parmsJson', JSON.stringify(this.parmsJson))
        this.$router.push({ name: 'm_goods_list', params: { type: 'out', id: this.parmsJson.depotId } });

      },
      //选择类型方法
      chooseTypeFun(e, type) {
        this.$set(this.parmsJson, type, e.value)
      },
      //填写商品数量
      inputEnterNumberFun(item, index) {
        if (item && item.outNumber > item.enterNumber) {
          this.$toast2.show('入库数量是' + item.enterNumber + ',出库数量不能大于入库数量', 'warn')
          item.outNumber = ''
          return false
        }
        let parmsJson = this.parmsJson
        let data = totalFun(this.parmsJson.commodityJson, 'outNumber')//统计箱、kg、方
        this.parmsJson = {
          ...parmsJson,
          ...data
        }
      },
      //删除商品
      deleteFun(index) {
        let self = this
        let parmsJson = this.parmsJson
        self.$dialog.confirm({
          title: '确定要删除吗？',
        }).then(() => {
          parmsJson.commodityJson.splice(index, 1)
          self.inputEnterNumberFun()
        }).catch(() => { })
      },
      jumpFun() {
        localStorage.removeItem('parmsJson') //清空缓存
        this.$router.push({ path: '/m_entry' });
      },
    },
  };
</script>
<style scoped>
  @import url('../../../assets/css/mobile/warehouse.css')
</style>